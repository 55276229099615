import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/maximum-overdrive-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1986 killer trucks movie, Maximum Overdrive"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 14</h1>
                    <h2>Maximum Overdrive</h2>
                    <h3>August 7, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Stephen King // <b>Starring:</b> Emilio Estevez &amp; Laura Harrington</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/14-Maximum-Overdrive-e27qp7e" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 14: Maximum Overdrive"></iframe>
                        </div>
                        <p><Link to="/transcripts/maximum-overdrive">Read the episode transcript</Link></p>
                        <p>This week kicks off our Stephen King Rules! series as Bring Me The Axe! takes on Stephen King's first and last credit as director, the undisputed document of cocaine confidence, Maximum Overdrive from 1986. It's an ill-advised adaptation of his own short story, Trucks, that features a lot explosions, big trucks, and Yeardley Smith screaming.</p>
                        <p>Trailer: ⁠<OutboundLink href="https://www.youtube.com/watch?v=pwYcnnbiRB4" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=pwYcnnbiRB4⁠</OutboundLink></p>
                        <p>FYI, the music in the trailer is from Halloween 3, not Christine.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/silver-bullet">Next Episode</Link></li>
                        <li><Link to="/episodes/sleepaway-camp">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)